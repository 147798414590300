<mat-card class="card-section" *ngIf="!loginDisplay">
    <mat-card-title>Angular single-page application built with MSAL Angular</mat-card-title>
    <mat-card-subtitle>Sign-in with Azure AD and get an ID Token</mat-card-subtitle>
    <mat-card-content>This sample demonstrates how to configure MSAL Angular to sign-in, sign-out and protect a route.</mat-card-content>
</mat-card>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="loginDisplay">

    <!-- Claim Column -->
    <ng-container matColumnDef="claim">
      <th mat-header-cell *matHeaderCellDef> Claim </th>
      <td mat-cell *matCellDef="let element"> {{element.claim}} </td>
    </ng-container>
  
    <!-- Value Column -->
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef> Value </th>
      <td mat-cell *matCellDef="let element"> {{element.value}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

<iframe src="https://sp2.achleitner.it" style="width: 1100px; height: 550px"></iframe>

<iframe src="https://sp2.achleitner.it/webapp" style="width: 1100px; height: 550px"></iframe>